<template>
  <div class="chats">
    <div
      v-for="(msgGrp, index) in formattedChatData.formattedChatLog"
      :key="index"
      :class="['chat', {'chat-left': msgGrp.senderId === formattedChatData.contact.id}]"
    >

      <div class="chat-body">
        <div
          v-for="(msgData, index) in msgGrp.messages"
          :key="index"
          class="chat-content"
        >
          <div>
            <p class="font-weight-bolder">{{ msgData.name }} ({{ msgData.organizationName }}) - {{ $moment.utc(msgData.time).format('ddd, MMM Do YYYY [at] HH:mm') }}</p>
            <hr>
          </div>
          <p>
            <span v-if="msgData.msg">{{ msgData.msg }}</span>
            <span v-if="msgData.fileUrl"><a class="text-underline text-white" :href="msgData.fileUrl" target="_blank">{{ msgData.filePath }}</a></span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { formatDateToMonthShort } from '@core/utils/filter'

export default {
  props: {
    chatData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const formattedChatData = computed(() => {
      const contact = {
        id: props.chatData.contact.id,
      }

      let chatLog = []
      if (props.chatData.chat) {
        chatLog = props.chatData.chat.chat
      }

      const formattedChatLog = []
      let chatMessageSenderId = chatLog[0] ? chatLog[0].senderId : undefined
      let msgGroup = {
        senderId: chatMessageSenderId,
        messages: [],
      }

      chatLog.forEach((msg, index) => {
        if (chatMessageSenderId === msg.senderId) {
          msgGroup.messages.push({
            msg: msg.message,
            time: msg.time,
            name: msg.senderName,
            organizationName: msg.senderOrganizationName,
            fileUrl: msg.fileUrl,
            filePath: msg.filePath,
          })
        } else {
          chatMessageSenderId = msg.senderId
          formattedChatLog.push(msgGroup)
          msgGroup = {
            senderId: msg.senderId,
            messages: [{
              msg: msg.message,
              time: msg.time,
              name: msg.senderName,
              organizationName: msg.senderOrganizationName,
              fileUrl: msg.fileUrl,
              filePath: msg.filePath,
            }],
          }
        }
        if (index === chatLog.length - 1) formattedChatLog.push(msgGroup)
      })

      return {
        formattedChatLog,
        contact,
      }
    })

    return {
      formattedChatData,
      formatDateToMonthShort,
    }
  },
}
</script>

<style>
.chat-app-window .chats .chat-left .chat-body .chat-content a {
  color: #6e6b7b !important;
}
</style>
